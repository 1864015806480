import { Client } from '@stomp/stompjs';
var SockJS = require('sockjs-client/dist/sockjs.js');

class WebSocketService {
  static instance = null;
  adminClient = null;
  clientClient = null;
  token = '';
  reconnectAttempts = 0;
  maxReconnectAttempts = 5;
  reconnectInterval = 5000;
  heartbeatInterval = 60000; // Increased to 30 seconds
  lastHeartbeat = Date.now();
  connectionState = 'disconnected';
  heartbeatTimer = null;

  callbacks = {
    onProductUpdate: null,
    onStockActionUpdate: null,
    onTradeCustomerUpdate: null,
    onOrderUpdate: null,
    onClientUpdate: null,
    onNewOrder: null,
    onConnectionStateChange: null,
  };

  constructor(adminBaseUrl, clientBaseUrl) {
    this.adminBaseUrl = adminBaseUrl;
    this.clientBaseUrl = clientBaseUrl;
  }

  static getInstance(adminBaseUrl, clientBaseUrl) {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService(adminBaseUrl, clientBaseUrl);
    }
    return WebSocketService.instance;
  }

  connect(token) {
    this.token = token;
    this.initializeAdminClient();
    this.initializeClientClient();
  }

  isConnected() {
    return this.connectionState === 'connected' &&
      this.adminClient?.connected &&
      this.clientClient?.connected;
  }

  setCallback(type, callback) {
    this.callbacks[type] = callback;
  }

  initializeAdminClient() {
    this.adminClient = this.createClient(
      `${this.adminBaseUrl}/updates`,
      this.onAdminConnected.bind(this)
    );
  }

  initializeClientClient() {
    this.clientClient = this.createClient(
      `${this.clientBaseUrl}/notifications`,
      this.onClientConnected.bind(this)
    );
  }

  createClient(url, onConnectCallback) {
    const client = new Client({
      brokerURL: url,
      connectHeaders: { Authorization: `Bearer ${this.token}` },

      reconnectDelay: this.reconnectInterval,
      heartbeatIncoming: 0,
      heartbeatOutgoing: 600000,
    });

    client.webSocketFactory = () => new SockJS(url);
    client.onConnect = onConnectCallback;
    client.onStompError = this.onError.bind(this);
    client.onDisconnect = this.onDisconnect.bind(this);
    client.activate();
    
    

    return client;
  }

  deactivate() {

    if (this.adminClient) {
      this.adminClient.deactivate();
      this.adminClient = null;
    }
    if (this.clientClient) {
      this.clientClient.deactivate();
      this.clientClient = null;
    }
    this.setConnectionState('disconnected');
    this.stopHeartbeat();
  }

  onAdminConnected() {

    this.setConnectionState('connected');
    this.adminClient?.subscribe('/products/update', this.onProductUpdateReceived.bind(this), { Authorization: `Bearer ${this.token}` });
    this.adminClient?.subscribe('/stocks/update', this.onStockActionReceived.bind(this), { Authorization: `Bearer ${this.token}` });
    this.adminClient?.subscribe('/tradeCustomers/update', this.onTradeCustomerRequest.bind(this), { Authorization: `Bearer ${this.token}` });
    this.startHeartbeat();
  }

  onClientConnected() {

    this.setConnectionState('connected');
    this.clientClient?.subscribe(`/user/SuperAdmin/updates/order`, this.onOrderUpdateReceived.bind(this), { Authorization: `Bearer ${this.token}` });
    this.clientClient?.subscribe(`/user/SuperAdmin/updates/client`, this.onClientReceived.bind(this), { Authorization: `Bearer ${this.token}` });
    this.clientClient?.subscribe(`/user/SuperAdmin/queue/orders_notifications`, this.onNewOrderReceived.bind(this), { Authorization: `Bearer ${this.token}` });
    this.startHeartbeat();
  }

  setConnectionState(state) {
    this.connectionState = state;
    this.callbacks.onConnectionStateChange?.(state);
    if (state === 'connected') {
      this.reconnectAttempts = 0;
    }
  }

  onDisconnect() {

    this.setConnectionState('disconnected');
    this.stopHeartbeat();
    this.handleReconnect();
  }

  onError(frame) {
    console.error('STOMP error', frame);
    this.stopHeartbeat();
    this.handleReconnect();
  }

  handleReconnect() {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts++;
      this.setConnectionState('connecting');

      const delay = Math.min(30000, Math.pow(2, this.reconnectAttempts) * 1000);
      setTimeout(() => {
        this.initializeAdminClient();
        this.initializeClientClient();
      }, delay);
    } else {
      console.error('Max reconnect attempts reached. Please check your connection.');
      this.setConnectionState('disconnected');
    }
  }

  startHeartbeat() {
    this.stopHeartbeat();
    this.heartbeatTimer = setInterval(() => {
      if (this.isConnected()) {
        this.sendHeartbeat();
      }
      this.checkConnection();
    }, this.heartbeatInterval);
  }

  stopHeartbeat() {
    if (this.heartbeatTimer) {
      clearInterval(this.heartbeatTimer);
      this.heartbeatTimer = null;
    }
  }

  sendHeartbeat() {
    this.adminClient?.publish({ destination: '/app', body: '' });
    this.clientClient?.publish({ destination: '/app', body: '' });
    this.lastHeartbeat = Date.now();
  }

  checkConnection() {
    if (Date.now() - this.lastHeartbeat > this.heartbeatInterval * 2) {

      this.stopHeartbeat();
      this.handleReconnect();
    }
  }

  onProductUpdateReceived(payload) {
    const { product, action } = JSON.parse(payload.body);
    this.callbacks.onProductUpdate?.(product, action);
  }

  onStockActionReceived(payload) {
    const { stockAction, action } = JSON.parse(payload.body);
    this.callbacks.onStockActionUpdate?.(stockAction, action);
  }

  onTradeCustomerRequest(payload) {
    const { object, action } = JSON.parse(payload.body);
    this.callbacks.onTradeCustomerUpdate?.(object, action);
  }

  onOrderUpdateReceived(payload) {
    const { object, action } = JSON.parse(payload.body);
    this.callbacks.onOrderUpdate?.(object, action);
  }

  onClientReceived(payload) {
    const { object, action } = JSON.parse(payload.body);
    this.callbacks.onClientUpdate?.(object, action);
  }

  onNewOrderReceived(payload) {
    const order = JSON.parse(payload.body);
    this.callbacks.onNewOrder?.(order);
  }
}

export default WebSocketService;