import { ShoppingCartIcon } from '@heroicons/react/outline';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useAuth } from '../security/Authentification';
import { OrderService } from '../service/OrderService';
import { ProductService } from '../service/ProductService';
import { StockTransactionService } from '../service/StockTransactionService';
import { UserService } from '../service/UserService';
import WebSocketService from '../service/WebSocketService';
import { AdminCreationService } from '../service/admincreationservice';

export const AppDataContext = createContext();

export const AppDataProvider = ({ children }) => {
    const [products, setProducts] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [orders, setOrders] = useState([]);
    const [stockActions, setStockActions] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [tradeCustomers, setTradeCustomers] = useState([]);
    const [profile,setProfile]=useState()

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const Auth = useAuth();
    const token = Auth.userIsAuthenticated() ? Auth.getToken() : null;
    const userRole = Auth.getUser()?.role[0];
    
    const [messageNotifications, setMessageNotifications] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [sales, setSales] = useState([]);
    
    // Generic function to handle WebSocket updates
    const handleWebSocketUpdate = useCallback((setter) => (object, action) => {
        setter(prevItems => {
            switch (action) {
                case 'update':
                    const indexToUpdate = prevItems.findIndex(p => p.id === object.id);
                    if (indexToUpdate >= 0) {
                        const updatedItems = [...prevItems];
                        updatedItems[indexToUpdate] = object;
                        return updatedItems;
                    }
                case 'delete':
                    return prevItems.filter(p => p.id !== object.id);
                case 'add':
                    return [object, ...prevItems];
                default:
                    return prevItems;
            }
        });
        
    }, []);


    const handleStockUpdate = useCallback(
        handleWebSocketUpdate(setStockActions),
        []
    );
    

    useEffect(() => {

        if (userRole === "SuperAdmin" || userRole === "Admin") {
        const webSocketService = WebSocketService.getInstance(
          'https://adminside.wholesaled.xyz',
          'https://clientside.wholesaled.xyz'
        );

        webSocketService.connect(token);

        webSocketService.setCallback('onProductUpdate', handleWebSocketUpdate(setProducts));
        webSocketService.setCallback('onStockActionUpdate', handleWebSocketUpdate(setStockActions));
        webSocketService.setCallback('onTradeCustomerUpdate', handleWebSocketUpdate(setTradeCustomers));
        webSocketService.setCallback('onOrderUpdate', handleWebSocketUpdate(setOrders));
        webSocketService.setCallback('onClientUpdate', handleWebSocketUpdate(setCustomers));

        webSocketService.setCallback('onNewOrder', (payload1) => {
            setSales((prevSales) => {
                const newSale = {
                    id: 1,
                    type: payload1.type,
                    title: payload1.title,
                    content: payload1.content,
                    timestamp: payload1.timestamp,
                    count: 1,
                    icon: <ShoppingCartIcon className="w-4 h-4 text-blue-500" />
                };

                const existingSaleIndex = prevSales.findIndex(sale => sale.type === newSale.type);
                if (existingSaleIndex !== -1) {
                    const updatedSales = [...prevSales];
                    updatedSales[existingSaleIndex] = {
                        ...updatedSales[existingSaleIndex],
                        count: updatedSales[existingSaleIndex].count + 1,
                        content: newSale.content,
                        timestamp: newSale.timestamp,
                    };
                    return updatedSales;
                } else {
                    return [newSale, ...prevSales];
                }
            });
        });

        return () => {
            webSocketService.deactivate();
        };
    }
    }, [token, handleWebSocketUpdate]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const [productsRes,profileRes] = await Promise.all([
                    ProductService.getAllProducts(token),
                    AdminCreationService.GetProfile(token)
                ])
                setProducts(productsRes.data);
                setProfile(profileRes.data)

                if (userRole === "SuperAdmin" || userRole === "Admin") {
                    const [clientsRes, stockTransRes, ordersRes] = await Promise.all([
                        UserService.getAllClients(token),
                        StockTransactionService.getAllPendingStockActions(token),
                        OrderService.getAllOrders(token)
                    ]);
                    setCustomers(clientsRes.data);
                    setStockActions(stockTransRes.data);
                    setOrders(ordersRes.data);
                }

                if (userRole === "SuperAdmin") {
                    const [adminsRes, tradeCustomersRes] = await Promise.all([
                        AdminCreationService.getAdmins(token),
                        UserService.getTradeCustomerRequests(token)
                    ]);
                    setAdmins(adminsRes.data);
                    setTradeCustomers(tradeCustomersRes.data);
                }
            } catch (err) {
                setError("Failed to fetch data");
            } finally {
                setLoading(false);
            }
        };

        if (token) {
            fetchData();
        }
    }, [token, userRole]);

    return (
        <AppDataContext.Provider value={{profile,setProfile,
            alerts, setAlerts, sales, setSales, messageNotifications, setMessageNotifications,
            products, customers, orders, stockActions, admins, tradeCustomers,
            setTradeCustomers, setAdmins, setProducts, setCustomers, setOrders, setStockActions,
            loading, error,handleStockUpdate
        }}>
            {children}
        </AppDataContext.Provider>
    );
};
