export const CLOUDINARY_URL = `https://api.cloudinary.com/v1_1/dlkvn0fpz/image/upload`;
export const UPLOAD_PRESET = 'd1zmuabv';

/* export const Client_url = 'http://localhost:9000'
export const Admin_url = 'http://localhost:9999'
export const messaging_url = 'https://messaging.wholesaled.xyz'
 */

export const Client_url = 'https://clientside.wholesaled.xyz'
export const Admin_url = 'https://adminside.wholesaled.xyz'
export const messaging_url = 'https://messaging.wholesaled.xyz'
 