import React from 'react';
import { Route } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { AppDataProvider } from '../context/AppDataContext';
import { useAuth } from './Authentification';

function SecureRoute({ component: Component, ...rest }) {
  const { userIsAuthenticated , getToken} = useAuth();
  const history = useHistory()

  const isTokenValid = () => {
    const token = getToken();
    if (!token) return false;

   
      return true;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        userIsAuthenticated() && isTokenValid() ? (
          <AppDataProvider>
            <Component {...props} />
          </AppDataProvider>
        ) : (
         // <Redirect to="/login" />
         //history.push("/login")
         null
        )
      }
    />
  );
}

export default SecureRoute;
